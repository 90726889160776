@import "./_functions.scss";
@import "./_variables.scss";
@import "./_breakpoints.scss";


.movie-modal {
	overflow-y: auto;
	text-align: center;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	width: 60vw;
	height: 500px;
	max-height: 80vh;

	margin: auto;
	padding: 20px;

	color: white;
	background-color: black;
	border: 2px solid #E50A12;
	border-radius: 4px;

	@include media-breakpoint-only(xs) {
		top: 50px;
		padding: 10px;
		width: 100vw;
		border-left: none;
		border-right: none;
		border-radius: 0;
	}
}

.mm-h1{
	@include media-breakpoint-only(xs) {
		font-size: 1.5rem;
	}
}

.mm-h5{
	@include media-breakpoint-only(xs) {
		font-size: 1.0rem;
	}
}

.mm-overview-container {
	width: 90%;
	margin: auto;

	@include media-breakpoint-only(md) {
		width: 100%;
	}

	@include media-breakpoint-only(sm) {
		width: 100%;
	}

	@include media-breakpoint-only(xs) {
		width: 100%;
	margin-top: 5px;
	}
}
.mm-overview-label {
	text-align: left;
	margin: 0;
}
.mm-overview-text {
	text-align: left;
	background-color: rgb(50,50,50);
	padding: 15px;
}

.mm-close-div {
	display: flex;
	justify-content: flex-end;
}

.mm-close {
	cursor: pointer;
	color: white;
	transform: scale(2);
	&:hover { color: #E50A12; }

	@include media-breakpoint-only(xs) {
		margin-top: 5px;
	}
}

.mm-text {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 4px;
	svg {
		font-size: 1.2rem;
	}
}

.mm-genres {
	margin-top: 12px;
	& > div {
		margin-right: 6px;
	}
}
