#App {
	background-color: #eee;
}

.no-wrap {
	white-space: nowrap;
}

.green {
	color: #00D65F;
}

.svg-align {
	display: inline-flex;
	position: relative;
	top: -0.20rem;
}

.red-tag {
	display: inline-block;
	font-size: 1rem;
	padding: 1px 7px;
	border-radius: 4px;
	color: #fff;
	background-color: #E50A12;
}

.red-btn {
	color: #fff;
	background-color: #E50A12;
	border: none;
	border-radius: 4px;
	user-select: none;

	&:hover {
		background-color: #C11119;
	}
	&:active {
		background-color: #99161D;
	}
	&:focus {
		outline: 2px solid transparent;
		outline-offset: 1px;
	}
    &:focus-visible {
		outline-color: dodgerblue;
	}
	&:disabled {
		opacity: 35%;
		color: #000;
		background-color: #ccc;
	}
}

.inverted-red-btn {
	color: #000;
	background-color: #eee;
	border: none;
	border-radius: 4px;
	user-select: none;

	&:hover {
		background-color: #ddd;
	}
	&:active {
		color: #fff;
		background-color: #E50A12;
	}
	&:focus {
		outline: 2px solid transparent;
		outline-offset: 1px;
	}
    &:focus-visible {
		outline-color: dodgerblue;
	}
	&:disabled {
		opacity: 60%;
		background-color: #eee;
	}
}

.loading {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	min-height: 100vh;
	height: 100%;

	font-size: 1.4rem;
	color: white;
	background-color: black;
}
