@import "./_functions.scss";
@import "./_variables.scss";
@import "./_breakpoints.scss";

$duration: 0.20s;


#MovieTracker {
	min-height: 100vh;
	height: 100%;
	width: 100%;
	padding: 100px 0 50px;
	text-align: center;
}

.mt-heading {
	font-family: Helvetica;
	font-size: 1.4rem;
	margin: 32px 0 24px;
}

.mt-status-options {
	display: flex;
	justify-content: center;
}


.mt-bottom {
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 2px;

  background-color: #E50A12;

  transform: scaleX(0);
  transition: transform $duration ease;
}


.mt-status-item {
	position: relative;
	cursor: pointer;

	margin: 0 50px;
	padding-bottom: 1px;
	text-align: center;
	font-size: 1.2rem;

	transition: color $duration ease 0s;

	&:hover {
		color: #E50A12;
		.mt-bottom { transform: scaleX(1); }
	}
}

.mt-active-item {
	color: #E50A12;
	.mt-bottom { transform: scaleX(1); }
}

table.mt-table {
	width: 90%;
	margin: 0 auto;
}

.mt-table-title {
	font-size: 1.2rem;
}

.mt-poster {
	// maintain aspect ratio 2:3
	min-width: 6vw; // 342px default
	min-height: 9vw; // 513px default
}

.mt-overview {
	text-align: left;

	@include media-breakpoint-only(md) {
		display: none;
	}

	@include media-breakpoint-only(sm) {
		display: none;
	}

	@include media-breakpoint-only(xs) {
		display: none;
	}
}

.mt-heart {
	font-size: 2rem;
	color: #E50A12;
}

.mt-heart-click {
	cursor: pointer;
	font-size: 2rem;
	&:hover { color: #E50A12; }
}

.mt-delete {
	cursor: pointer;
	font-size: 2rem;
	&:hover { color: #E50A12; }
}

.mt-check {
	cursor: pointer;
	font-size: 2rem;
	&:hover { color: #00D65F; }
}
