@import "./_functions.scss";
@import "./_variables.scss";
@import "./_breakpoints.scss";


.pl-page {
	min-height: 100vh;
	min-width: 100vw;

	background: linear-gradient(
		rgba(0, 0, 0, 0.60), 
		rgba(0, 0, 0, 0.50) 
	), url("../images/background.png");

	@include media-breakpoint-only(xs) {
		background: black;
	}
}

.pl-logo {
	margin: 18px 18px 12px;
	height: 48px;

	@include media-breakpoint-only(xs) {
		margin: 10px;
		height: 34px;
	}
}

.pl-heading {
	margin-bottom: 30px;
	text-align: left;

	color: white;
	font-size: 2rem;
	font-weight: bold;
}

.login-error {
	color: #E50A12;
	font-size: 1.1rem;
}

.signup-error {
	text-align: left;
	color: #E50A12;
	font-size: 1.1rem;
}

.pl-form-container {
	margin: auto;
	text-align: center;

	width: 450px;
	max-width: 100vw;
	padding: 50px 60px 40px;

	background-color: #000;
	border-radius: 4px;

	@include media-breakpoint-only(xs) {
		padding: 15px;
		background: black;
	}
}

.pl-form {
	width: 100%;
	margin-bottom: 50px;
}


.pl-input {
	width: 100%;
	height: 50px;
	margin-top: 15px;
	padding: 5px 15px;

	font-size: 1rem;

	outline: none;
	color: white;
	background-color: #333;
	border: 2px solid #333;
	box-sizing: border-box;
	border-radius: 4px;


	&:focus {
		border-color: #808080;
		background-color: #454545;
	}
}

.top {
	margin-top: 0;
}

.pl-button {
	width: 100%;
	height: 50px;
	margin-top: 45px;
	font-weight: bold;
}

.pl-redirect {
	&:hover {
		color: white;
	}
}
