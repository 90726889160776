@import "./_functions.scss";
@import "./_variables.scss";
@import "./_breakpoints.scss";


#Search {
	min-height: 100vh;
	height: 100%;
	padding: 100px 0 50px;
	text-align: center;
}

.search-form {
	display: flex;
	justify-content: center;
}

.search-input {
	height: 36px;
	width: 285px;
	padding: 4px 12px;

	font-size: 1.2rem;

	outline: none;
	border: 2px solid grey;
	border-right: none;
	border-radius: 4px 0 0 4px;

	&:focus {
		border-color: #E50A12;
		& + .search-button { border-color: #E50A12; }
	}
}

.search-button {
	display: flex;
	align-items: center;

	font-size: 1.2rem;
	color: grey;
	background-color: white;

	outline: none;
	border: 2px solid grey;
	border-left: none;
	border-radius: 0 4px 4px 0;

	&:focus {
		outline: none;
		color: #E50A12;
	}
}

.start-search {
	display: inline-block;
	margin: 80px 0 auto;
	text-align: left;
	& > div {
		color: #aaa;
		margin: 0 4px 8px;
	}
	& > img {
		width: 320px;
	}
}

.empty-search-text {
	margin: 10%;
	font-size: 2rem;
	color: #505050;

	@include media-breakpoint-only(xs) {
		font-size: 1rem;
	}
}

.search-results {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	justify-items: center;
	grid-row-gap: 50px;
	margin: 50px 0;
	
	@include media-breakpoint-only(lg) {
		grid-template-columns: 1fr 1fr 1fr;
	}

	@include media-breakpoint-only(md) {
		grid-template-columns: 1fr 1fr;
	}

	@include media-breakpoint-only(sm) {
		grid-template-columns: 1fr 1fr;
	}

	@include media-breakpoint-only(xs) {
		grid-template-columns: 1fr 1fr;
	}
}

.results-page-button {
	width: 100px;
	height: 30px;

	margin: 0 10px;
	padding: 0;
}

.movie-card {
	position: relative;
	cursor: pointer;

	// maintain aspect ratio
	width: 16vw; // 342px default
	height: 24vw; // 513px default

	&:hover .mc-title { opacity: 1; }

	@include media-breakpoint-only(lg) {
		width: 28vw;
		height: 42vw;
	}

	@include media-breakpoint-only(md) {
		width: 35vw;
		height: 52.5vw;
	}

	@include media-breakpoint-only(sm) {
		width: 35vw;
		height: 52.5vw;
	}

	@include media-breakpoint-only(xs) {
		width: 40vw;
		height: 60vw;
	}
}

.mc-title {
	position: absolute;
	bottom: 0;
	width: 100%;

	padding: 2px;
	text-align: center;
	box-sizing: border-box;

	font-size: 1.5rem;
	color: #fff;
	background-color: rgba(0,0,0, 0.80);

	opacity: 0;
	transition: opacity 0.20s ease 0s;
}

.mc-status {
	position: absolute;
	top: 0;
	right: 0;

	padding-left: 3px;
	box-sizing: border-box;

	background-color: rgba(0,0,0, 0.80);
	border-radius: 0 0 0 12px;

	font-size: 1.5rem;
}

.mc-svg  {
	color: #fff;
	font-size: 1.3rem;
	margin: 0 5px;
}

.mc-poster {
	width: 100%;
	height: 100%;
}
